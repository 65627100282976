import { some, forEach } from 'lodash';
import React from 'react';

import connect from 'components/lib/connect';
import FeatureNotification from './feature_notification';
import FeatureNotificationTypes from 'scripts/domain/models/agent_notification/feature_notification_types';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const releaseNotesSignup = {
  feature: FeatureNotificationTypes.IN_APP_NOTIFICATION,
  roleFlags: ['teamManagement'],
  key: 'release-notes-signup-notification',
  text: 'Get the latest from Gladly in your email inbox',
  subtext:
    'We share updates about new features, bug fixes, and important news related to Gladly through our release notes. Sign up to stay informed about Gladly.',
  url: 'https://connect.gladly.com/product-updates/',
  urlText: 'Sign Up',
};

const ivrHolidaySchedule = {
  feature: FeatureNotificationTypes.IN_APP_NOTIFICATION,
  roleFlags: ['ivrConfigurationManagement'],
  key: 'ivr-holiday-notification',
  text: '[Action Required] Update IVR Holiday Schedule',
  subtext:
    'Review and update your Gladly IVR to ensure smooth holiday operations. Check that the "Holiday Check" Schedule node displays the correct year to route calls accurately during holiday hours, keeping callers from entering the queue if your contact center is closed.',
  url: 'https://vimeo.com/873523009/df973f98e6',
  urlText: 'WATCH',
};

const CURRENT_FEATURES = [releaseNotesSignup, ivrHolidaySchedule];

export function FeatureNotificationList({ agentPreferences, organization }) {
  const isFeatureEnabled = useIsFeatureEnabled();

  let featureNotifications = [];
  forEach(CURRENT_FEATURES, featureProps => {
    if (shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization)) {
      featureNotifications.push(<FeatureNotification {...featureProps} />);
    }
  });

  if (!featureNotifications.length) {
    return null;
  }

  return <React.Fragment>{featureNotifications}</React.Fragment>;
}

const FeatureNotificationListContainer = connect(mapStateToProps)(FeatureNotificationList);

function mapStateToProps({ getProvider }) {
  const agentPreferences = getProvider('agentPreferences').get();
  const organization = getProvider('auth').get()?.claims;

  return {
    agentPreferences,
    organization,
  };
}

export function useHasFeaturesToShow(agentPreferences, organization) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return some(CURRENT_FEATURES, featureProps => {
    return shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization);
  });
}

function shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization) {
  const roleRestricted = featureProps.roleFlags && featureProps.roleFlags.length;
  const restrictedAndHasRole = some(featureProps.roleFlags, roleFlag => isFeatureEnabled(roleFlag));
  const flagRestricted = featureProps.flag;

  const orgRestricted = !!featureProps.orgIds;
  const belongsToOrg = some(featureProps.orgIds, orgId => orgId === organization?.orgId);

  const currentTime = Date.now();
  const startRestricted = !!featureProps.startTime;
  const afterStart = startRestricted ? currentTime > featureProps.startTime : false;
  const endRestricted = !!featureProps.endTime;
  const beforeEnd = endRestricted ? currentTime < featureProps.endTime : false;

  return (
    (!orgRestricted || belongsToOrg) &&
    (!roleRestricted || restrictedAndHasRole) &&
    (!flagRestricted || isFeatureEnabled(featureProps.flag)) &&
    (!startRestricted || afterStart) &&
    (!endRestricted || beforeEnd) &&
    !agentPreferences.hasSeenFeatureNotification(featureProps.feature)
  );
}

export default FeatureNotificationListContainer;
