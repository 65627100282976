import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { animated, useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import CommonButton from 'components/common/button';
import RelativeDateTime from 'components/lib/date/relative_date_time';
import RemoveToast from 'actions/toast/remove_toast';
import Toast from 'models/toast';
import TrackToast from 'actions/toast/track_toast';
import useExecuteAction from 'components/hooks/use_execute_action';
import useTimeout from 'components/hooks/use_timeout';

export function Card({ action, children, className, toast }) {
  const { createdAt } = toast;

  const cardRef = useRef();
  const { closeToast, onMouseEnter, onMouseLeave, transition } = useAnimateCard(cardRef, toast);

  return (
    <React.Fragment>
      {transition((style, showToast) => {
        return showToast ? (
          <AnimatingWrapper style={{ height: style.height, opacity: style.opacity }}>
            <StyledCard
              as={animated.div}
              className={className}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              ref={cardRef}
              style={{ scale: style.scale, x: style.x, y: style.y }}
            >
              <Main>
                <Content>{children}</Content>
                <Dismiss onClick={closeToast} />
              </Main>
              <Footer>
                <RelativeDateTime timestamp={createdAt} />
                {action}
              </Footer>
            </StyledCard>
          </AnimatingWrapper>
        ) : null;
      })}
    </React.Fragment>
  );
}

Card.propTypes = {
  action: PropTypes.node, // Action button that generally navigates to something
  className: PropTypes.string, // Necessary for doing `styled(Card)` if you want to override the styles, don't pass in className manually! Use styled-components
  children: PropTypes.node, // Card content
  toast: PropTypes.instanceOf(Toast),
};

function useAnimateCard(ref, toast) {
  const { id, duration } = toast;

  const executeAction = useExecuteAction();
  useEffect(() => {
    executeAction(TrackToast, { toast, track: 'Shown' });
  }, []);

  const startMs = useMemo(() => Date.now(), []);

  // Local show, when it becomes false, will tell the animation to proceed to its finished state,
  // using the `leave` property in the config below
  const [localShow, setLocalShow] = useState(true);
  const automaticallyCloseToast = useCallback(() => {
    setLocalShow(false);
  }, []);
  const manuallyCloseToast = useCallback(() => {
    setLocalShow(false);
    executeAction(TrackToast, { toast, track: 'Dismissed' });
  }, []);

  // We initialize the close timer here using our initial duration. Once the timer hits 0,
  // we'll set localShow to false, which initializes the animation to close.
  const [timeout, setTimeout] = useState(duration);
  useTimeout(automaticallyCloseToast, timeout);

  // But we pause the close timer if the agent hovers over the card. When they stop hovering,
  // we resume the timer again.
  const { onMouseEnter, onMouseLeave } = usePauseOnHover(startMs, toast, setTimeout);

  // Actually removes the toast from the store, only used _after_ the animation has complete,
  // hence why only onRest calls this function.
  const removeToast = useCallback(() => {
    executeAction(RemoveToast, id);
  }, [id]);

  const animationConfig = {
    config: { mass: 1, tension: 185, friction: 26 },

    // Our card starts off the screen to the left initially
    from: {
      opacity: 1,
      scale: 1,
      // react-spring automatically converts x to properly interpolated translate3d which should engage
      // hardware acceleration for the animation.
      x: '100%',
      y: '0%',
    },

    // When it mounts, it slides over to its intended position and then waits there until
    // localShow is set to false
    enter: () => next =>
      next({
        height: ref.current.getBoundingClientRect().height + CARD_TOP_MARGIN,
        opacity: 1,
        scale: 1,
        x: '0%',
        y: '0%',
      }),

    // When it closes, it fades out, it's height goes to 0, and it gets slightly smaller,
    // giving it the impression of disappearing downwards.
    leave: {
      height: 0,
      opacity: 0,
      scale: 0.8,
      y: '-100%',
    },

    // When the closing animation is all finished, we can finally remove the toast from the toast
    // store.
    onRest: () => {
      if (!localShow) {
        removeToast();
      }
    },
  };

  return {
    closeToast: manuallyCloseToast,
    isPaused: !timeout,
    onMouseEnter,
    onMouseLeave,
    transition: useTransition(localShow, animationConfig),
  };
}

function usePauseOnHover(startMs, toast, setTimeout) {
  const executeAction = useExecuteAction();
  const resumeTimeRef = useRef(startMs);
  const remainingTimeRef = useRef(toast.duration);
  const hasHoveredOnceRef = useRef(false);

  // When the agent starts hovering, we calculate the time that's elapsed since we last resumed time
  // (this initializes to the createdAt time). We then save how much time we have left, and then
  // clear the timeout that would otherwise close our toast.
  const onMouseEnter = useCallback(() => {
    const elapsedMs = Date.now() - resumeTimeRef.current;
    remainingTimeRef.current = remainingTimeRef.current - elapsedMs;
    setTimeout(null);

    if (!hasHoveredOnceRef.current) {
      hasHoveredOnceRef.current = true;
      executeAction(TrackToast, { toast, track: 'Hovered' });
    }
  }, []);

  // When the agent stops hovering, we reset the resume time to now, and set the timeout to our
  // remaining time we saved above.
  const onMouseLeave = useCallback(() => {
    resumeTimeRef.current = Date.now();
    setTimeout(remainingTimeRef.current > 0 ? remainingTimeRef.current : 0);
  }, []);

  return {
    onMouseEnter,
    onMouseLeave,
  };
}

const AnimatingWrapper = animated.div;

const CARD_TOP_MARGIN = 8;

export const StyledCard = styled.div`
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.green400};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow.default};
  margin-top: ${CARD_TOP_MARGIN}px;
  padding: 16px;
  position: relative;
  overflow: hidden;
  width: 308px;
`;

const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Dismiss({ onClick }) {
  return (
    <Close onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </Close>
  );
}

export const Close = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 20px;
  justify-content: center;
  width: 20px;

  &:hover {
    color: ${p => p.theme.colors.gray600};
  }
`;

export const Content = styled.div`
  margin-right: 16px;
  margin-bottom: 12px;
`;
export const Footer = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  time {
    color: ${p => p.theme.colors.gray600};
  }
`;
export const Body = styled.span``;
export const Bold = styled.span`
  font-weight: bold;
`;

export function Button({ id, onClick, ...rest }) {
  const executeAction = useExecuteAction();
  const decoratedOnClick = useCallback(
    evt => {
      executeAction(RemoveToast, id);
      onClick(evt);
    },
    [id, onClick]
  );

  return <StyledButton onClick={decoratedOnClick} {...rest} />;
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const StyledButton = styled(CommonButton).attrs({ type: CommonButton.Types.OUTLINE })`
  padding: 6px 12px;
`;
