import _ from 'lodash';
import moment from 'moment';

import ConversationItemType, { AutomaticConversationItemTypes } from 'models/conversation_item_type';
import Communicator from 'models/communicator';

/*
The current items of the LATEST conversation are the only items which are guaranteed to be in the conversation history. Below methods should be synced with: https://github.com/sagansystems/supernova/blob/master/services/history/domain/current_converation_items.go
{
  latestNonAutomaticInitiatorItemsOfEachType,
  latestManualCustomerItem,
  activePhoneCalls,
  openTasks
}
*/

export function getLatestManualItem({ conversationHistory, conversationId, filter }) {
  return conversationHistory.findBy({
    filter: item => {
      return isManualItemOfConversation({ item, conversationId }) && (!filter || filter(item));
    },
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });
}

export function getLatestManualCustomerConversationItemAttrs({ conversationHistory, conversationId, fields }) {
  return conversationHistory.findBy({
    filter: item => {
      return (
        isManualItemOfConversation({ item, conversationId }) && (item.initiator || {}).type === Communicator.CUSTOMER
      );
    },
    select: _.union(fields, ['timestamp']),
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });
}

export function isManualItemOfConversation({ item, conversationId }) {
  return (
    item.conversationId === conversationId &&
    (item.initiator || {}).type !== Communicator.AUTOMATED &&
    AutomaticConversationItemTypes.indexOf(item.content.type) === -1
  );
}

// The need of loading latest NoReplyNeeded item is why we fetch all latest items with non-automatic initiator, instead of only latest manual items (not of type in AutomaticConversationItemTypes).
export function getLatestNoReplyNeededTimestamp(conversationHistory) {
  let latestNoReplyNeeded = conversationHistory.findBy({
    filter: ci => ci.content.type === ConversationItemType.NO_REPLY_NEEDED,
    select: ['timestamp'],
    sortBy: item => -1 * moment(item.timestamp).valueOf(),
  });
  return latestNoReplyNeeded && latestNoReplyNeeded.timestamp;
}

export function getOpenTaskCount({ conversationHistory, conversationId }) {
  let taskIds = conversationHistory.findAll({
    filter: ci => isOpenTaskOfConversation(ci, conversationId),
    select: ['id'],
  });
  return taskIds.length;
}

function isOpenTaskOfConversation(ci, conversationId) {
  return ci.conversationId === conversationId && ci.content.type === ConversationItemType.TASK && !ci.content.closedAt;
}

export function hasActivePhoneCall({ conversationHistory, conversationId }) {
  let phoneCallContents = conversationHistory.findAll({
    filter: ci => ci.conversationId === conversationId && ci.content.type === ConversationItemType.PHONE_CALL,
  });
  return phoneCallContents.some(ci => ci.content.isActive());
}
