import Communicator from 'models/communicator';
import ConversationItem from 'models/conversation_item';
import CustomChannelMessage from 'models/custom_channel_message';

export default function createAgentCustomChannelMessage({ agentProfile, content, conversationId, customerId }) {
  return ConversationItem.create({
    conversationId,
    content: new CustomChannelMessage(content),
    customerId,
    initiator: {
      id: agentProfile.id,
      type: Communicator.AGENT,
    },
  });
}
