import FetchCommentsAndFollowersForTask from 'actions/task/fetch_comments_and_followers_for_task';

export default class TaskGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  handleAddSuccess(payload) {
    let taskInfo = this.context.stores.taskInfo.getPendingNew();
    if (taskInfo) {
      this.context.stores.taskInfo.commitPendingNew();
      this.context.executeAction(FetchCommentsAndFollowersForTask, { taskId: taskInfo.id });
    }
  }

  handleAddErrors(errorsDto) {}

  handleUpdateSuccess(entityId, payload) {}

  handleUpdateErrors(entityId, errorsDto) {}
}
