import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { find, isEmpty, intersection } from 'lodash';

import Inbox from 'models/location/inbox';
import KbAdmin from 'models/location/kb_admin';
import Link from 'components/common/link';
import LinkOutStroke from 'components/common/icons/stroke/link-out-stroke';
import Liveboards from 'models/location/liveboards';
import NavigateTo from 'actions/current_location/navigate_to';
import Reports from 'models/location/reports';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { HotkeysShownContext } from 'components/contexts/hotkeys';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const ActiveItem = css`
  background-color: ${p => p.theme.colors.gray200};
  font-weight: bold;
  &:before {
    background-color: ${p => p.theme.colors.green500};
    bottom: 0;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

const StyledLinkOutStroke = styled(LinkOutStroke)`
  height: 14px;
  width: 14px;
  margin-left: 4px;
`;

export const StyledLink = styled(Link)`
  color: ${p => p.theme.colors.black};
  align-items: center;
  display: flex;
  height: 32px;
  padding: 6px 24px;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.black};
    text-decoration: none;
  }
`;

const Beta = styled.span`
  color: ${p => p.theme.colors.gray700};
  padding-left: ${p => p.theme.spacing.medium};
`;

const StyledLine = styled.div`
  height: 1px;
  background-color: ${p => p.theme.colors.gray300};
  margin: 2px 0;

  & + & {
    display: none;
  }
`;

const StyledMenuItem = styled.div`
  cursor: pointer;
  padding: 0;
  position: relative;
  &:hover {
    background-color: ${p => p.theme.colors.green100};
  }
  ${p => p.isActive && ActiveItem}
`;

export const StyledExternalLink = styled.a`
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.green400};
    text-decoration: none;
  }
`;

const PrivacyPolicySection = styled.div`
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 8px 0;
  position: absolute;
`;

const PrivacyPolicyLink = styled.a`
  color: ${p => p.theme.colors.gray800};
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 24px;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.gray800};
    text-decoration: none;
    background-color: ${p => p.theme.colors.green100};
  }
`;

export default function NavigationMenu({ currentAgentRoles, onClose }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const { dispatchHotkeysShown } = useContext(HotkeysShownContext);
  const showHotkeys = useCallback(() => dispatchHotkeysShown('on'), [dispatchHotkeysShown]);
  const isSystemAdmin = currentAgentRoles.includes('system-admin');

  return (
    <div className={`navigationMenu navigationMenuV2`}>
      <StyledLine />
      {isFeatureEnabled('internalAgentActions') ? (
        <MenuOption locationModel={Inbox} locationPath="" onClick={onClose} title="Home" />
      ) : null}
      {isSystemAdmin && isFeatureEnabled('sidekickV2') ? (
        <StyledLink
          className="navigationMenu-option"
          href="/sidekick"
          onClick={evt => {
            evt.preventDefault();
            window.location.assign('/sidekick');
          }}
        >
          Sidekick<Beta>beta</Beta>
        </StyledLink>
      ) : null}
      {isFeatureEnabled('kbManagement') || isFeatureEnabled('internalAgentActions') ? (
        <MenuOption locationModel={KbAdmin} onClick={onClose} title="Answers" />
      ) : null}
      {isFeatureEnabled('viewReports') ? (
        <MenuOption locationModel={Reports} onClick={onClose} title="Reports" />
      ) : null}
      {isFeatureEnabled('viewLiveboards') ? (
        <MenuOption locationModel={Liveboards} onClick={onClose} title="Liveboards" />
      ) : null}
      <StyledLine />
      <RoleBasedMenuItem
        currentUserRoles={currentAgentRoles}
        data-aid="settings-menu"
        requiredRoleFeatures={['adminSettings', 'devManagement', 'debuggingView', 'kbManagement']}
      >
        <StyledLink
          className="navigationMenu-option"
          href="/admin/home"
          onClick={evt => {
            evt.preventDefault();
            window.location.assign('/admin/home');
          }}
        >
          Settings
        </StyledLink>
      </RoleBasedMenuItem>
      <StyledLine />
      <StyledMenuItem>
        <StyledExternalLink
          className="navigationMenu-option"
          data-aid="navigationMenu-new-features"
          href="https://connect.gladly.com/docs/help-documentation/release-notes/"
          tabIndex="-1"
          target="release-notes"
        >
          What's new in Gladly
          <StyledLinkOutStroke />
        </StyledExternalLink>
      </StyledMenuItem>
      <StyledMenuItem>
        <StyledExternalLink
          className="navigationMenu-option"
          data-aid="navigationMenu-help-link"
          href="https://connect.gladly.com/docs/help-documentation/"
          tabIndex="-1"
          target="help"
        >
          Help Docs
          <StyledLinkOutStroke />
        </StyledExternalLink>
      </StyledMenuItem>
      <RoleBasedMenuItem
        currentUserRoles={currentAgentRoles}
        data-aid="contact-support-menu"
        requiredRoleFeatures={['contactSupport']}
      >
        <StyledExternalLink
          className="navigationMenu-option"
          data-aid="navigationMenu-help-link"
          href="/support.html"
          tabIndex="-1"
          target="support"
        >
          Contact Support
          <StyledLinkOutStroke />
        </StyledExternalLink>
      </RoleBasedMenuItem>
      <StyledLine />
      <StyledMenuItem>
        <StyledLink
          className="navigationMenu-option"
          data-aid="navigationMenu-shortcuts-link"
          onClick={showHotkeys}
          tabIndex="-1"
        >
          Keyboard Shortcuts
        </StyledLink>
      </StyledMenuItem>
      <PrivacyPolicySection>
        <PrivacyPolicyLink
          data-aid="navigationMenu-privacy-policy-link"
          href="https://www.gladly.com/privacy-policy/"
          rel="noopener noreferrer"
          tabIndex="-1"
          target="_blank"
        >
          Privacy Policy
        </PrivacyPolicyLink>
      </PrivacyPolicySection>
    </div>
  );
}

NavigationMenu.propTypes = {
  currentAgentRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const MenuOption = ({ locationModel, onClick, title }) => {
  const executeAction = useExecuteAction();
  const handleClick = useCallback(
    evt => {
      evt.preventDefault();
      executeAction(NavigateTo, locationModel.create());
      onClick();
    },
    [executeAction, locationModel, onClick]
  );

  const menuPath = getLocationUrl(locationModel.create());
  const currentLocation = window.location.pathname.split('/')[1];
  const isActiveItem = menuPath.split('/')[1] === currentLocation;
  return (
    <StyledMenuItem isActive={isActiveItem}>
      <StyledLink className="navigationMenu-option" href={menuPath} onClick={handleClick}>
        {title}
      </StyledLink>
    </StyledMenuItem>
  );
};

MenuOption.propTypes = {
  locationModel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

/**
 * Displays navigation menu item based on the intersection of the required roles and/or role features with the current
 * user roles and features. If there is no intersection (user is not in one of the required roles OR does not have at
 * least one of the required role features), then the menu item is not rendered. Any combination of roles and role
 * features can be specified, empty array means "no restrictions". When both roles and role features are specified,
 * the user is supposed to meet both requirements (meaning that both roles AND role features are taken into account)
 *
 * @param {string[]} currentUserRoles
 * @param {*} children
 * @param {string} [className]
 * @param {string} [dataAid]
 * @param {string[]} requiredRoles
 * @param {string[]} requiredRoleFeatures
 * @return {JSX.Element|null}
 * @constructor
 */
const RoleBasedMenuItem = ({
  children,
  className,
  currentUserRoles,
  'data-aid': dataAid,
  requiredRoles,
  requiredRoleFeatures,
}) => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const satisfiesRoles = isEmpty(requiredRoles) ? true : intersection(requiredRoles, currentUserRoles).length > 0;
  const satisfiesRoleFeatures = isEmpty(requiredRoleFeatures)
    ? true
    : !!find(requiredRoleFeatures, feature => isFeatureEnabled(feature));

  if (isEmpty(children) || !satisfiesRoles || !satisfiesRoleFeatures) return null;
  return (
    <StyledMenuItem className={className} data-aid={dataAid}>
      {children}
    </StyledMenuItem>
  );
};

RoleBasedMenuItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  'data-aid': PropTypes.string,
  requiredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  requiredRoleFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};
RoleBasedMenuItem.defaultProps = {
  currentUserRoles: [],
  requiredRoles: [],
  requiredRoleFeatures: [],
};
